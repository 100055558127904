import UserVerifyEmail from '@/components/Modals/UserVerifyEmail.vue';

export default {
  components: {
    UserVerifyEmail,
  },

  data () {
    return {};
  },

  methods: {
    openUserVerifyEmailModal () {
      this.$modal.show(UserVerifyEmail, {}, {
        width: 450,
        height: 350,
      });
    }
  },

  mounted () {
    if (!this.$store.getters['auth/isEmailVerified'] && this.$store.getters['auth/isCustomer']) {
      this.openUserVerifyEmailModal();
    }
  }
};

<template>
    <v-main class="custom--v-main">
        <app-header>
            <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
                Profile information
            </h3>
        </app-header>

        <app-customer-start-new-order-slider/>

        <section class="tw-p-[30px]">
            <div class="tw-mt-[30px] tw-mb-[40px]">
                <div class="tw-flex tw-items-center tw-gap-[10px]">
                    <div class="tw-rounded-full tw-overflow-hidden">
                        <img :src="user.image" :alt="user.first_name" class="tw-w-[60px] tw-aspect-square tw-object-cover">
                    </div>
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-[7px]">
                        <strong class="tw-font-semibold tw-text-[#0D3659]">{{ user.first_name + ' ' + user.last_name }}</strong>
                        <button class="tw-bg-white tw-p-[3px_10px] tw-border tw-border-solid tw-border-[#DDDDDD] tw-rounded-[6px] tw-text-[14px] tw-font-medium tw-text-[#0D3659] hover:tw-bg-[#0D3659] hover:tw-text-white hover:tw-border-[#0D3659] tw-duration-300" @click="upload">Upload image</button>
                    </div>
                </div>
            </div>

            <v-row class="account-settings__row">
                <v-col class="col-lg-10 col">
                    <v-row class="mb-0">
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    First name
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="text"
                                    placeholder="Your name"
                                    class="custom--v-text-field"
                                    v-model="$v.user.first_name.$model"
                                />
                                <span style="color: red;" v-if="$v.user.first_name.$error"
                                      v-text="firstNameValidationMessage"/>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    Last name
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="text"
                                    placeholder="Your last name"
                                    class="custom--v-text-field"
                                    v-model="$v.user.last_name.$model"
                                />
                                <span style="color: red;" v-if="$v.user.last_name.$error"
                                      v-text="lastNameValidationMessage"/>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    Phone number
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="text"
                                    placeholder="Your phone number"
                                    class="custom--v-text-field"
                                    v-maska="phone_mask"
                                    v-model="$v.user.phone.$model"
                                />
                                <span style="color: red;" v-if="$v.user.phone.$error"
                                      v-text="phoneNumberValidationMessage"/>
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    Email
                                </div>
                                <v-text-field
                                    single-line
                                    hide-details
                                    outlined
                                    type="text"
                                    placeholder="Your email"
                                    class="custom--v-text-field"
                                    disabled
                                    v-model="user.email"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="12" class="py-0">
                            <div class="d-flex fill-height align-end justify-end">
                                <div>
                                    <v-btn
                                        class="px-10"
                                        elevation="0"
                                        color="primary"
                                        large
                                        @click="save"
                                    >
                                        Save Changes
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>

        <v-divider class="account-settings__divider"></v-divider>

        <section class="reset-password">
            <h3 class="tw-text-[20px] tw-font-semibold tw-text-[#0D3659] tw-mb-[30px]">Reset password</h3>

            <v-row class="reset-password__row">
                <v-col class="col-lg-10 col">
                    <v-row class="mb-4">
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    Current Password
                                </div>
                                <v-text-field
                                    :append-icon="showCurrent ? 'fa-eye' : 'fa-eye-slash'"
                                    :type="showCurrent ? 'text' : 'password'"
                                    name="input-10-2"
                                    hint="At least 8 characters"
                                    @click:append="showCurrent = !showCurrent"
                                    single-line
                                    hide-details
                                    outlined
                                    background-color="white"
                                    class="custom--v-text-field"
                                    v-model="userPassword.old_password"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    New Password confirmation
                                </div>
                                <v-text-field
                                    :append-icon="showConfirmation ? 'fa-eye' : 'fa-eye-slash'"
                                    :type="showConfirmation ? 'text' : 'password'"
                                    name="input-10-2"
                                    hint="At least 8 characters"
                                    @click:append="showConfirmation = !showConfirmation"
                                    single-line
                                    hide-details
                                    outlined
                                    background-color="white"
                                    class="custom--v-text-field"
                                    v-model="userPassword.new_password_confirmation"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="mb-4">
                                <div class="tw-text-[#0D3659] tw-font-medium tw-mb-[8px]">
                                    New Password
                                </div>
                                <v-text-field
                                    :append-icon="showNew ? 'fa-eye' : 'fa-eye-slash'"
                                    :type="showNew ? 'text' : 'password'"
                                    name="input-10-2"
                                    hint="At least 8 characters"
                                    @click:append="showNew = !showNew"
                                    single-line
                                    hide-details
                                    outlined
                                    background-color="white"
                                    class="custom--v-text-field"
                                    v-model="userPassword.new_password"
                                />
                            </div>
                        </v-col>
                        <v-col cols="12" md="6" class="py-0">
                            <div class="d-flex align-end justify-end mt-4">
                                <div class="mt-4">
                                    <v-btn
                                        class="px-10"
                                        elevation="0"
                                        color="primary"
                                        large
                                        @click="savePassword"
                                        :disabled="saveBtnIsDisable"
                                    >
                                        Save password
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </section>
    </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { maska } from "maska";
import { phone_mask } from "@/plugins/custom_masks";
import { mapGetters, mapActions, mapMutations } from "vuex";
import AppImageCropper from '@/components/AppImageCropper.vue';
import AppCustomerStartNewOrderSlider from '@/components/AppCustomerStartNewOrderSlider.vue';
import UserVerifyEmail from '@/mixins/UserVerifyEmail';

export default {
    components: { AppCustomerStartNewOrderSlider },

    mixins: [ validationMixin, UserVerifyEmail ],

    directives: {
        maska
    },

    data () {
        return {
            phone_mask,

            userPassword: {
                old_password: '',
                new_password: '',
                new_password_confirmation: ''
            },
            showCurrent: false,
            showNew: false,
            showConfirmation: false,
            saveBtnIsDisable: true
        }
    },

    validations: {
        user: {
            first_name: { required },
            last_name: { required },
            phone: { required, minLength: minLength(19) }
        },
    },

    methods: {
        ...mapActions('auth', ['updateCustomer']),
        ...mapMutations('auth', ['SET_USER']),
        ...mapActions('auth', ['updatePassword']),


        upload () {
            this.$modal.show(AppImageCropper, {
                image: this.user.image,
                name: 'customer'
            }, {
                width: 560,
                height: "auto"
            }, {
                closed: async () => {
                    await this.load();
                }
            })
        },

        save () {
            if (! this.$v.$invalid) {
                this.SET_USER(this.user);
                this.updateCustomer(this.user);
            }
        },

        savePassword () {
            if (this.user.old_password !== '') {
                this.updatePassword(this.userPassword);
            }
        }
    },

    computed: {
        ...mapGetters('auth', ['user']),

        firstNameValidationMessage () {
            if (! this.$v.user.first_name.required) {
                return 'First Name is required!';
            } else {
                return '';
            }
        },

        lastNameValidationMessage () {
            if (! this.$v.user.last_name.required) {
                return 'Last Name is required!';
            } else {
                return '';
            }
        },

        phoneNumberValidationMessage () {
            if (! this.$v.user.phone.required) {
                return 'Phone number is required!';
            } else if (! this.$v.user.phone.minLength) {
                return 'Phone number is not valid!';
            } else {
                return '';
            }
        },
    },

    watch: {
        userPassword: {
          deep: true,

          handler(value) {
              this.saveBtnIsDisable = ! (value.new_password === value.new_password_confirmation && value.old_password.length > 0);
          }
        },
    }

}
</script>
<style scoped lang="scss">
::v-deep {
    .custom--v-text-field {
        background-color: #fff !important;

        input {
            color: #0D3659;
        }
    }
}
</style>

<template>
    <v-main class="custom--v-main">
        <app-header>
            <h3 class="tw-text-[25px] tw-text-[#0D3659] tw-font-semibold">
                Reviews
            </h3>
            <template v-if="!isCustomer" #right>
                <v-btn depressed color="primary" class="v-btn v-size--medium" @click.prevent="openInviteReviewer">
                    <span>Invite a Reviewer</span>
                </v-btn>
            </template>
        </app-header>

        <app-customer-start-new-order-slider v-if="isCustomer"/>

        <!--            tabs in reviews -->
        <main class="reviews">
            <v-container class="custom--v-container">
                <div class="reviews-header">
                    <v-tabs
                        v-model="tab"
                        class="custom--v-tabs"
                    >
                        <v-tab v-for="item in tabs" :key="item.tab">
                            {{ item.tab }}
                        </v-tab>
                    </v-tabs>
                    <h3 v-if="isCustomer" class="reviews__header mt-2 mb-5 text-secondary">
                        At TopHelpers.com, you can write reviews as a customer for your helpers. Your helpers can also write
                        reviews for you in return. This two-way review system ensures that customers and helpers can provide
                        honest feedback to each other.
                    </h3>
                    <h3 v-else class="reviews__header mt-2 mb-5 text-secondary">On TopHelpers.com you can be reviewed and
                        write a
                        review for your clients as well. We do not edit or
                        remove reviews of both sides. To keep the best standards, reviews will be archived after three
                        years.</h3>

                </div>

                <v-divider class="mb-5"></v-divider>

                <div class="reviews-main">
                    <div v-if="isLoading" style="max-width: 1232px">
                        <v-skeleton-loader
                            v-bind="attrs"
                            type="table-heading"
                            elevation="0"
                        >
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            v-for="n in 10"
                            :key="n"
                            v-bind="attrs"
                            elevation="0"
                            type="list-item-avatar-three-line, actions"
                        >
                        </v-skeleton-loader>
                    </div>

                    <div v-else>
                        <div class="reviews__stars">
                            <div class="d-flex">
                                <v-rating
                                    half-icon="fa-star-half-alt"
                                    half-increments
                                    readonly
                                    color="#FBAB1E"
                                    background-color="#FBAB1E"
                                    class="custom--v-rating mb-5"
                                    :value="getStars(tab)"
                                />
                                <span class="ml-3 font-family-gilroy text-secondary font-semibold text-xl">
                                {{ getReviewsCount }} out of 5
                                </span>
                            </div>
                            <p class="mt-2 text-md font-semibold text-secondary">
                                {{ getReviewCount(tab) }}
                            </p>
                        </div>
                        <v-tabs-items class="custom--v-tabs-items" v-model="tab">
                            <v-tab-item>
                                <review-card
                                    v-for="(review, index) in reviews.sent.reviews"
                                    :review="review"
                                    :key="index"
                                    :tab="tab"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <review-card
                                    v-for="(review, index) in reviews.received.reviews"
                                    :review="review"
                                    :key="index"
                                    :tab="tab"
                                    received
                                />
                            </v-tab-item>
                        </v-tabs-items>
                    </div>

                </div>
            </v-container>
        </main>

    </v-main>
</template>

<script>
import ReviewCard from "@/views/Reviews/ReviewCard.vue";
import AppRating from "@/components/AppRating.vue";
import { mapActions } from "vuex/dist/vuex.esm.browser";
import { mapGetters } from "vuex";
import loading from "@/mixins/loading.js";
import InviteReviewer from "@/views/Dashboard/DashboardInviteReviewer.vue";
import AppCustomerStartNewOrderSlider from '@/components/AppCustomerStartNewOrderSlider.vue';
import UserVerifyEmail from '@/mixins/UserVerifyEmail';

export default {
    name: "Reviews",
    mixins: [ loading, UserVerifyEmail ],

    components: { AppCustomerStartNewOrderSlider, AppRating, ReviewCard },

    data () {
        return {
            attrs: {
                class: 'mb-5',
                boilerplate: false,
                elevation: 2,
            },
            tab: null,
            tabs: [
                { tab: 'Reviewed' },
                { tab: 'Received' }
            ],
        }
    },

    computed: {
        ...mapGetters('review', ['reviews']),
        ...mapGetters('auth', ['isCustomer']),

        getReviewsCount () {
            return this.tab === 0 ? Number(this.reviews.sent.avg_star).toFixed(1) : Number(this.reviews.received.avg_star).toFixed(1)
        }
    },

    methods: {
        ...mapActions('review', ['index']),

        loadReviews () {
            this.startLoading(async () => {
                await this.index();
            })
        },

        getStars (tab) {
            return tab === 0 ? Number(this.reviews.sent.avg_star) : Number(this.reviews.received.avg_star)
        },

        getReviewCount (tab) {
            if (tab === 0) {
                return this.reviews.sent.count_star <= 1 ? this.reviews.sent.count_star + ' active review' : this.reviews.sent.count_star + ' active reviews';
            }
            return this.reviews.received.count_star <= 1 ? this.reviews.received.count_star + ' active review' : this.reviews.received.count_star + ' active reviews';
        },

        openInviteReviewer () {
            this.$modal.show(InviteReviewer, {}, {
                width: 814,
                height: 605,
            })
        },
    },

    created () {
        this.loadReviews();
    },

    watch: {
        tab (v) {
            if (v === 0) {
                this.stars = {
                    avg_star: Number(this.reviews.sent.avg_star).toFixed(1),
                    count_star: this.reviews.sent.count_star
                }
            } else {
                this.stars = {
                    avg_star: Number(this.reviews.received.avg_star).toFixed(1),
                    count_star: this.reviews.received.count_star
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep {
    .custom--v-container {
        @media (min-width: 968px) {
            margin-left: 18px !important;
            margin-right: 18px;
        }
    }
    .v-tabs > .v-tabs-bar, .v-tabs-items {
        background-color: #F5F5F9 !important;
    }

    .v-tab {
        width: 93px;
        height: 36px;
        border-radius: 4px;
        background: #fff;
        color: #0D3659;
        text-transform: capitalize;
        margin-right: 15px;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .v-tab--active {
        background: #8F3985 !important;
        color: #fff;
    }

    .v-tabs-slider {
        display: none;
    }

}
</style>

<template>
    <v-main class="custom--v-main">
        <app-header class="dashboard-header">
            <div class="[&_.v-tabs-slider-wrapper]:tw-hidden">
                <v-tabs
                    v-model="tab"
                    align-with-title
                    color="#0D3659"
                    class="[&_.v-tabs-bar]:!tw-bg-[transparent] custom--dashboard-header--v-tabs"
                >
                    <v-tab
                        v-for="{title, count} in tabs"
                        :key="title"
                        class="before:tw-hidden !tw-m-[0_30px_0_0] !tw-p-0 [&.v-tab--active_.v-badge]:tw-text-[#0D3659]"
                    >
                        <v-badge
                            inline :content="count.toString()"
                            class="
                            tw-text-[18px]
                            tw-text-[#aaaaaa]
                            tw-font-semibold
                            tw-capitalize
                            custom--dashboard-header--v-badge
                            [&>span]:!tw-m-[0_0_0_0px]
                            [&>span>span]:tw-flex
                            [&>span>span]:tw-items-center
                            [&>span>span]:tw-justify-center
                            [&>span>span]:tw-p-0
                            [&>span>span]:tw-w-[30px]
                            [&>span>span]:tw-h-[30px]
                            [&>span>span]:tw-rounded-[8px]
                            [&>span>span]:!tw-text-[16px]"
                        >
                            {{ title }}
                        </v-badge>
                    </v-tab>
                </v-tabs>
            </div>

            <template v-slot:right>
                <v-btn class="tw-me-2" depressed color="primary" @click.prevent="$router.push({ name: 'user-invite-people'})">
                    <span>Invite a friend</span>
                </v-btn>

                <v-btn depressed color="primary" @click.prevent="openNewOrderModal">
                    <span>Start New Order</span>
                </v-btn>
            </template>
        </app-header>

        <app-customer-start-new-order-slider/>

        <div class="tw-mx-[30px] tw-my-[18px]">
            <v-skeleton-loader v-if="isLoading" type="card@10" class="tw-grid tw-gap-[18px]"/>

            <v-tabs-items v-else v-model="tab" class="custom--v-tabs-items">
                <v-tab-item>
                    <div class="tw-grid tw-gap-[18px]" :class="{'tw-mb-[18px]': Object.keys(delivery).length > 0 }" v-if="isActiveDeliveriesExist">
                        <user-dashboard-delivery-card
                            v-for="delivery_item in delivery"
                            :key="delivery_item.id"
                            :delivery="delivery_item"
                        />
                    </div>

                    <div class="tw-grid tw-gap-[18px]" v-if="isActiveMovingsExist">
                        <dashboard-card
                            v-for="order in active"
                            :key="order.id"
                            :order="order"
                        />
                    </div>

                    <v-alert v-if="!isActiveDeliveriesExist && !isActiveMovingsExist" color="primary" text class="tw-text-center">
                      No active orders. Start a new delivery or move.
                    </v-alert>
                </v-tab-item>

                <v-tab-item>
                    <div class="tw-grid tw-gap-[18px] tw-mb-[20px]" :class="{'tw-mb-[18px]': Object.keys(archiveDelivery).length > 0 }" v-if="isArchiveDeliveriesExist">
                      <user-dashboard-delivery-card
                        v-for="delivery_item in getArchiveDelivery"
                        :key="delivery_item.id"
                        :delivery="delivery_item"
                      />
                    </div>

                    <div class="tw-grid tw-gap-[18px]" v-if="isArchiveMovingsExist">
                        <dashboard-card
                            v-for="order in archive"
                            :key="order.id"
                            :order="order"
                        />
                    </div>

                  <v-alert v-if="!isArchiveDeliveriesExist && !isArchiveMovingsExist" color="primary" text class="tw-text-center">
                    No archive orders.
                  </v-alert>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </v-main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import loading from "@/mixins/loading";
import AppNewCustomerOrder from "@/components/Modals/AppNewCustomerOrder"
import AppNew from "@/components/Modals/AppCustomerStartNewOrder.vue"
import DashboardCard from "@/views/Dashboard/DashboardCard";
import UserDashboardDeliveryCard from "@/views/UserDashboard/UserDashboardDeliveryCard.vue";
import AppOrder from "@/components/Modals/AppOrder.vue";
import AppCustomerStartNewOrderSlider from '@/components/AppCustomerStartNewOrderSlider.vue';
import UserVerifyEmail from '@/mixins/UserVerifyEmail';

export default {
    mixins: [ loading, UserVerifyEmail ],

    components: {
        DashboardCard,
        UserDashboardDeliveryCard,
        AppNew,
        AppCustomerStartNewOrderSlider
    },

    data () {
        return {
            tab: null,
        }
    },

    methods: {
        ...mapActions('user_orders', ['index']),

        openNewOrderModal () {
            this.$modal.show(AppNew, {}, {
                width: 740,
                height: 630,
            });
        },
    },

    computed: {
        ...mapState('user_orders', ['active', 'archive', 'delivery', 'archiveDelivery']),
        ...mapGetters('user_orders', ['activeDeliveriesCount' ,'archivedDeliveriesCount', 'getArchiveDelivery']),

        /**
         * Active, Archive tabs
         *
         * @return {{count, title}[]}
         */
        tabs () {
            const item = (title, count) => ({ title, count });

            return [
                item('Active', this.activeDeliveriesCount),
                item('Archive', this.archivedDeliveriesCount),
            ];
        },

        isActiveMovingsExist() { return this.isLoaded && this.active.length; },
        isArchiveMovingsExist() { return this.isLoaded && this.archive.length; },
        isActiveDeliveriesExist() { return this.isLoaded && this.delivery.length; },
        isArchiveDeliveriesExist() { return this.isLoaded && this.archiveDelivery.length; },
    },

    created () {
        this.startLoading(async () => await this.index());

        //if mail redirect the dashboard the order modal will open
        if(this.$route.fullPath.includes('order_id')) {
            this.$modal.show(AppOrder, {
                orderId: Number(this.$route.query.order_id),
            }, {
                width: '800px',
                height: '660px',
            });
        }
    },
}
</script>

<style lang="scss" scoped>

.custom--v-text-field::v-deep {
    width: 263px;
    height: 43px;
    padding-left: 15px;
    background: #F5F5F9;
    border-radius: 35px;

    .v-text-field .v-input__control .v-input__slot {
        padding-right: 0 !important;
    }

    fieldset {
        border: none;
    }

    .v-input__slot {
        padding-right: 0 !important;

        .v-input__append-inner {
            margin-top: 12px !important;
        }

    }

    .v-input__prepend-outer {
        margin-top: 13px !important;
        margin-right: 0 !important;
    }

    i {
        font-size: 18px !important;
        color: #aaa !important;
    }
}

.custom--v-tab::v-deep {
    .v-badge__badge {
        display: flex !important;
        align-items: center !important;
        background: #E7EFF3 !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}

.custom--dashboard-header--v-badge::v-deep {
    .v-badge__badge {
        display: flex !important;
        align-items: center !important;
        background: #fff !important;
        border-radius: 8px !important;
        color: #6B6D81 !important;
    }
}
</style>

<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-gap-[20px] tw-p-[30px] tw-text-center">
    <div>
      <img src="/assets/images/check-mail.png" alt="" class="tw-w-[100px]">
    </div>
    <h4 class="tw-text-[30px] tw-font-bold tw-text-[#0D3659]">
      Verify your email address
    </h4>
    <p>
      You've entered <span class="tw-text-[#0D3659] tw-font-bold">{{ user.email }}</span> as the email address for your account.
      <br>
      Please, check your email and verify this email address.
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
 computed: {
   ...mapGetters('auth', ['user']),
  }
};
</script>
